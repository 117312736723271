// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-6-ways-police-union-contracts-block-accountability-tsx": () => import("./../../src/pages/6-ways-police-union-contracts-block-accountability.tsx" /* webpackChunkName: "component---src-pages-6-ways-police-union-contracts-block-accountability-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contracts-tsx": () => import("./../../src/pages/contracts.tsx" /* webpackChunkName: "component---src-pages-contracts-tsx" */),
  "component---src-pages-contributions-tsx": () => import("./../../src/pages/contributions.tsx" /* webpackChunkName: "component---src-pages-contributions-tsx" */),
  "component---src-pages-instagram-city-tsx": () => import("./../../src/pages/instagram-city.tsx" /* webpackChunkName: "component---src-pages-instagram-city-tsx" */),
  "component---src-pages-instagram-state-tsx": () => import("./../../src/pages/instagram-state.tsx" /* webpackChunkName: "component---src-pages-instagram-state-tsx" */),
  "component---src-pages-no-more-tsx": () => import("./../../src/pages/no-more.tsx" /* webpackChunkName: "component---src-pages-no-more-tsx" */),
  "component---src-pages-research-basis-tsx": () => import("./../../src/pages/research-basis.tsx" /* webpackChunkName: "component---src-pages-research-basis-tsx" */),
  "component---src-templates-city-page-tsx": () => import("./../../src/templates/CityPage.tsx" /* webpackChunkName: "component---src-templates-city-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-research-page-tsx": () => import("./../../src/templates/ResearchPage.tsx" /* webpackChunkName: "component---src-templates-research-page-tsx" */),
  "component---src-templates-state-page-tsx": () => import("./../../src/templates/StatePage.tsx" /* webpackChunkName: "component---src-templates-state-page-tsx" */)
}

